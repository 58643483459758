@import "index", "highlight";

.archive {
  font-size: 85%; }

.archive time {
  display: inline-block;
  min-width: 10ch;
  margin: 0 .2em; }



.categories {
  color: $link-color;
  margin-bottom: .15em; }







.hidden {
  display: none; }





.icons svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
  transition: .2s color;
  vertical-align: middle;
  margin-bottom: .15em; }







.related div {
  flex: 1 50%;
  min-width: 15em; }

.related span {
  display: block;
  color: reduce(100); }

.related a {
  font-size: 1.2em;
  font-weight: bold; }

.next {
  text-align: right; }






